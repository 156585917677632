import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import './style.css'
import Home from './views/home'
import Wentzville from './views/wentzville'
import Ambassador from './views/ambassador'

const App = () => {
  return (
    <Router>
      <div>
        <Route component={Home} exact path="/" />
        <Route component={Wentzville} exact path="/wentzville" />
        <Route component={Ambassador} exact path="/ambassador" />
      </div>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
