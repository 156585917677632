import React from 'react'

import { Helmet } from 'react-helmet'

import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Wentzville Shipping App</title>
        <meta property="og:title" content="Wentzville Shipping App" />
      </Helmet>
      <iframe
        src="https://threadstudioatl.retool.com/embedded/public/937843c8-f03c-402a-bb1a-eeae32f4deef"
        allowFullScreen
        className="home-iframe"
      ></iframe>
    </div>
  )
}

export default Home
