import React from 'react'

import { Helmet } from 'react-helmet'

import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Ambassador Shipping App</title>
        <meta property="og:title" content="Ambassador Shipping App" />
      </Helmet>
      <iframe
        src="https://threadstudioatl.retool.com/embedded/public/8cc462f0-bcd3-45bf-ad82-1a2a33a58ca3"
        allowFullScreen
        className="home-iframe"
      ></iframe>
    </div>
  )
}

export default Home
